import React, { useState, useEffect } from 'react';
import './App.css';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import {db} from './firebase'

const App = () => {
  document.title = "Pehnawa Inventory";
  const [products, setProducts] = useState([]);
  const [activeCategory, setActiveCategory] = useState('All');
  const [newProduct, setNewProduct] = useState({ name: '', buyPrice: '', category: 'Blankets' });
  const [showAddProductPopup, setShowAddProductPopup] = useState(false);
  const [categories, setCategories] = useState([]);
  const margin = 30;//percent
  const dbItems = collection(db, 'items');
  const dbCategories = collection(db, 'categories');

  const getItems = async ()=>{
    const allDataItems = await getDocs(dbItems);
    const fetchedItems = allDataItems.docs.map(doc=>doc.data());
    setProducts(fetchedItems);
  }

  const addItem = async (newProduct) =>{
    await addDoc(dbItems, {
      ...newProduct
    })
  }

  const getCategories = async () => {
    const allDataCategories = await getDocs(dbCategories);
    const fetchedCategories = allDataCategories.docs.map(doc=>doc.data());
    setCategories(fetchedCategories[0].categoryNames);
  }

  useEffect(()=>{
    getItems();
    getCategories();
  }, [])

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleAddProduct = async () => {
    if (newProduct.name && newProduct.buyPrice) {
      const newProductEntry = {
        ...newProduct,
        buyPrice: parseFloat(newProduct.buyPrice),
      };
      setProducts([...products, newProductEntry]);
      setNewProduct({ name: '', buyPrice: '', category: 'Blankets' });
      setShowAddProductPopup(false);
      addItem(newProductEntry);
    }
  };

  const handleAddButtonClick = () => {
    setShowAddProductPopup(true);
  };

  const handleClosePopup = (e) => {
    if (e.target.className === 'popup-overlay' || e.target.className.includes('cancel-button')) {
      setShowAddProductPopup(false);
    }
  };

  const filteredProducts = products.filter(
    (product) => activeCategory === 'All' || product.category === activeCategory
  );

  return (
    <div className="app" onClick={handleClosePopup}>
      <div className="header-container">
        <h1 className="title">{"INVENTORY"}</h1>
        <button className="add-button-icon top-right" onClick={handleAddButtonClick}>+</button>
      </div>
      <div className="category-tabs">
        {['All', ...categories].map((category, i) => (
          <button
            key={i}
            className={`category-button ${activeCategory === category ? 'active' : 'inactive'}`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="product-list">
        {filteredProducts.length > 0 ? filteredProducts.map((product) => (
          <div key={product.id} className="product-card">
            <h2 className="product-name">{product.name}</h2>
            <p className="product-detail">Sell Price: ₹{product.buyPrice + ((product.buyPrice * margin)/100)}</p>
          </div>
        )) :
          <div className="product-card">
            <p className="product-detail">No Items here...</p>
          </div>
        }
      </div>

      {showAddProductPopup && (
        <div className="popup-overlay">
          <div className="add-product-popup">
            <h2 className="add-title">Add Item</h2>
            <input
              type="text"
              name="name"
              placeholder="Product Name"
              value={newProduct.name}
              onChange={handleInputChange}
              className="input-field round-input"
            />
            <input
              type="number"
              name="buyPrice"
              placeholder="Buy Price"
              value={newProduct.buyPrice}
              onChange={handleInputChange}
              className="input-field round-input"
            />
            <select name="category" value={newProduct.category} onChange={handleInputChange} className="select-field round-input">
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            <button onClick={handleAddProduct} className="add-button round-button">
              Add Product
            </button>
            <button className="cancel-button round-button">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;

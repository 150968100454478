// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDD1LzVHV6pv_m63u8KMl07R4gwpC-qlJc",
  authDomain: "pehnawa-inventory.firebaseapp.com",
  projectId: "pehnawa-inventory",
  storageBucket: "pehnawa-inventory.appspot.com",
  messagingSenderId: "521883329141",
  appId: "1:521883329141:web:7656e018303aa0f843ce3c",
  measurementId: "G-BJDJNFTFBS"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(firebaseApp);

export { db, firebaseApp };
